import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { IxSelect } from '@siemens/ix-angular';
import { ApiService } from 'src/app/services/api.service';
import { NextBtnStateService } from 'src/app/services/next-btn-state.service';

export interface CommandOptions {
  label: string;
  value: string;
}

@Component({
  selector: 'app-crmt-command-selector',
  templateUrl: './crmt-command-selector.component.html',
  styleUrls: ['./crmt-command-selector.component.css']
})
export class CrmtCommandSelectorComponent implements OnInit {

  @Output() getSelectedCommandType = new EventEmitter<string>();
  public options: CommandOptions[];

  versionNumber = new FormControl('', [
    Validators.required,
    Validators.pattern(/^\d+$/), // Only numeric values
    this.rangeValidator(0, 65535), // Range between 1 and 65535
  ]);
  vehicleType = new FormControl('',Validators.required);
  vehicleNumber = new FormControl('',[
    Validators.required,
    Validators.maxLength(9),
    Validators.pattern(/^[^\s]{1,9}$/), //max 9 characters
  ]);
  languageCode = new FormControl('',Validators.required);
  
  public selectedType?: string
  public nrMode: boolean = false;
  public isSelectInvalid: boolean = false;
  constructor( private _apiService: ApiService,
    private nextBtnStateService: NextBtnStateService) { 
    this.options = [
      { label: 'Activate software', value: 'activate-software' },
      //{ label: 'Cab radio details', value: 'write-details' },
      { label: 'Reboot radio', value: 'reboot-radio' },
      { label: 'Clear fan usage log', value: 'clear-fan-usage-log' },
      { label: 'Clear fault log', value: 'clear-fault-log' },
    ];
  }

  ngOnInit(): void {
    this.getSelectedCommandType.emit(this.selectedType);
    this._apiService.getAccountInfo().subscribe((data:any) => {
      if(data.nrMode){
        this.nrMode = true;
      }
      else{
        this.nrMode = false;
      }
    })
    this.initializeFormListeners();
  }

  initializeFormListeners() {
    this.versionNumber.valueChanges.subscribe(() => {
      this.validateActivateSoftware();
    });
    this.vehicleType.valueChanges.subscribe(() => {
      this.validateWriteDetails();
    });

    this.languageCode.valueChanges.subscribe(() => {
      this.validateWriteDetails();
    });

    this.vehicleNumber.valueChanges.subscribe(() => {
      this.validateWriteDetails();
    });
  }

  private validateActivateSoftware(): any {
    let done = false;
    if(this.versionNumber.valid){
      done = true
    }
      return {
        done,
        type: this.selectedType,
        swVersion: this.versionNumber.value
    }
  }

  private validateWriteDetails() {
    let done = false;
    if (
      this.vehicleType.valid &&
      this.languageCode.valid &&
      this.vehicleNumber.valid
    ) {
      done = true;
    } 
    
    return {
      done,
      type: this.selectedType,
      stockType: this.vehicleType.value,
      stockNumber: this.vehicleNumber.value,
      languageCode: this.languageCode.value
    } 
    
  }
  private markAllFieldsTouched(){
    this.vehicleType.markAsTouched();
    this.languageCode.markAsTouched();
    this.vehicleNumber.markAsTouched();
  } 

  public typeChanged(event: any): void {
    this.nextBtnStateService.setDisableNextBtn(false)
    this.selectedType = event.detail[0]
    this.getSelectedCommandType.emit(this.selectedType)
  }

  public getData() {

    let response: any

    if(this.selectedType == 'activate-software') {
      this.versionNumber.markAsTouched();
      response = this.validateActivateSoftware()

    }
    else if(this.selectedType == 'write-details') {
      this.markAllFieldsTouched();
      response = this.validateWriteDetails()
    }
    else {

      const done: boolean = Boolean(this.selectedType)

      response = {
        done,
        type: this.selectedType
      }
    }

    return response
  }
 
  public clearData(){
    this.vehicleType.reset();
    this.languageCode.reset();
    this.vehicleNumber.reset();
    this.versionNumber.reset();
  }

  rangeValidator(minValue: number, maxValue: number) {
    return (control: AbstractControl) => {
      const value = Number(control.value);
      if (value < minValue) {
        return { minValue: { requiredValue: minValue, actualValue: value } };
      }
      if (value > maxValue) {
        return { maxValue: { requiredValue: maxValue, actualValue: value } };
      }
      return null;
    };
  }
}

