import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoAuthUtils } from '../utils/auth-utils';

export interface FaultCodeItem {
  faultCode: string;
  faultType: string;
  faultDescription: string;
}

export interface FaultClassItem {
  faultClassCode: string;
  faultClassStatus: string;
}


@Injectable({
  providedIn: 'root'
})
export class FieldMappingService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  constructor(private http: HttpClient) { }

  public FaultCode(): Observable<FaultCodeItem[]> {
    const url = this.apiUrl + `field-mapping/fault-code`
    return this.getFaultDetails(url)
  }

  public FaultClass(): Observable<FaultClassItem[]> {
    const url = this.apiUrl + `field-mapping/fault-class`
    return this.getFaultDetails(url)
  }

  private getFaultDetails(url :string){
    return CognitoAuthUtils.getAccessToken().pipe(  
      switchMap((accessToken: string) => {

        const options = {
          headers: new HttpHeaders().set('Authorization', accessToken)
        }
        return this.http.get<any>(url,options).pipe(
          switchMap((response: any) => {
            return of(response.message)
          }),
          catchError((error) => {
            console.error(`Error making API ${url} call`, error);
            throw error;  
          })
        );
      })
    );  
  }  

}
