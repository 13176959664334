import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { fetchAuthSession, getCurrentUser, signInWithRedirect } from '@aws-amplify/auth';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit{
  loading = true;
  error: string | null = null;
  constructor(private router: Router) {}


  ngOnInit(): void {
    this.processAuthCallback();
  }
  private async processAuthCallback() {
    getCurrentUser()
        .then(({ username, signInDetails }) => {
          fetchAuthSession().then(({ tokens: session }) => {
            this.router.navigate(['/fleet-list']);
          });
        })
        .catch(async error => {
          console.error('Error getting authenticated user:', error);
        }
    );   
  }
}
