import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';

import { fetchAuthSession, getCurrentUser,  signInWithRedirect } from 'aws-amplify/auth';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean{
    return getCurrentUser()
    .then(({ username, signInDetails }) => {
      return fetchAuthSession().then(({ tokens: session }) => {
        return true; 
      });
    })
    .catch(async error => {
      console.error('Error getting authenticated user:', error);
      return false;
    });
  }


}
