import { Component, OnInit } from '@angular/core';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService, UserLevel } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})

export class ProfileComponent implements OnInit {

  public name$!: Observable<string>;
  public email!: string
  public group!: string
  public organization!: string
  public description!: string

  constructor(private readonly permissions: PermissionsService) { }

  public ngOnInit(): void {
    // Get display name from cognito user attributes
    this.permissions.getUserLevel().subscribe(level => {
      getCurrentUser().then(user => {
        if (user) {
          fetchUserAttributes().then(attributes => {
            this.email = attributes.email!
            this.group = this.getUserLevel(level)
            this.organization = attributes['custom:organization'] as string
            this.name$ = new Observable(observer => {
              observer.next(attributes.name);
              observer.complete();
            });
          });
        }
      });
    })          
  }
  private getUserLevel(level: UserLevel){
    let userLevel = "NONE";
    if(level === UserLevel.SUPER){
      userLevel = "Super user"
      this.description = "Siemens admin only."
    } else if(level === UserLevel.PRIVILEGED){
      userLevel = "Privileged user"
      this.description = "Top level access - can add subgroups."
    } else if(level === UserLevel.STANDARD){
      userLevel = "Standard user"
      this.description = "Restricted access - can perform operations for maintenance and update."
    } else if(level === UserLevel.READ_ONLY){
      userLevel = "Readonly user"
      this.description = "No ability to modify any aspect of the system. View / download data only."
    }
    return userLevel;
  }
}
