import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoAuthUtils } from '../utils/auth-utils';

export type OrientationConfig = {
  x: number;
  y: number;
  z: number;
}

export type Orientation = {
  config: OrientationConfig,
  quaternion: number[]
}

export type OrientationPackageData = {
  device: string;
  roll: number;
  pitch: number;
  yaw: number;
  offset: number
  buildNo: number;
  trainClass: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrientationToolService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  constructor(private http: HttpClient) { }

  // Get the valid orientations for a given radio model
  public getOrientations(radioModel: string): Observable<Orientation[]> {

    return CognitoAuthUtils.getAccessToken().pipe(  
      switchMap((accessToken: string) => {

        const options = {
          headers: new HttpHeaders().set('Authorization', accessToken)
        }
        const url = this.apiUrl + `orientation-positions/${radioModel}`
        return this.http.get<any>(url,options).pipe(
          switchMap((response: any) => {
            return of(response.message)
          }),
          catchError((error) => {
            console.error(`Error making API ${url} call`, error);
            throw error;  
          })
        );
      })
    ); 
  }

  public createPackage(
    packageData: OrientationPackageData) {

    return CognitoAuthUtils.getAccessToken().pipe(  
      switchMap((accessToken: string) => {
        const options = {
          headers: new HttpHeaders().set('Authorization', accessToken)
        }
        const url = this.apiUrl + 'orientation-package'

        return this.http.post<any>(url, packageData, options).pipe(
          switchMap((response: any) => {
            return of(response)
          }),
          catchError((error) => {
            console.error(`Error making API ${url} call`, error);
            throw error;  
          })
        );
      })
    );
  }
}
