import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CognitoAuthUtils } from '../utils/auth-utils';

export interface StockHistoryItem {
  stockNumber: string;
  timestamp: string;
}

export interface CertificateItem {
  id: string;
}


@Injectable({
  providedIn: 'root'
})
export class DeviceInfoService {

  // The url for the REST API
  private apiUrl = environment.backendConfig.url

  constructor(
    private http: HttpClient) { }

  public Certificates(deviceId: string): Observable<CertificateItem[]> {

    const url = this.apiUrl + `device-info/certificates/${deviceId}`
    return this.getDeviceInfo(url)
    
  }

  public StockHistory(deviceId: string): Observable<StockHistoryItem[]> {

    const url = this.apiUrl + `device-info/stock-history/${deviceId}`
    return this.getDeviceInfo(url)
  }

  public Inventory(deviceId: string): Observable<any> {

    const url = this.apiUrl + `device-info/inventory/${deviceId}`
    return this.getDeviceInfo(url)
  }

  private getDeviceInfo(url :string){
    return CognitoAuthUtils.getAccessToken().pipe(  
      switchMap((accessToken: string) => {

        const options = {
          headers: new HttpHeaders().set('Authorization', accessToken)
        }
        return this.http.get<any>(url,options).pipe(
          switchMap((response: any) => {
            return of(response.message)
          }),
          catchError((error) => {
            console.error(`Error making API ${url} call`, error);
            throw error;  
          })
        );
      })
    );  
  }
}
