import { fetchAuthSession } from "@aws-amplify/auth";
import { Observable } from "rxjs";

export class CognitoAuthUtils {

    // function to get the Cognito Access Token
    public static getAccessToken(): Observable<string> {
        return new Observable((observer) => {
            fetchAuthSession()
                .then((session) => {
                    const accessToken = session.tokens?.accessToken.toString();
                    observer.next(accessToken);
                    observer.complete();
                })
                .catch((error) => {
                    console.error('Error fetching access token', error);
                    observer.error(error);
                });
        });
    }
}