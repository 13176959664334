import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IxModule } from '@siemens/ix-angular';
import { AgGridModule } from 'ag-grid-angular';
import { MatDatepickerModule } from '@angular/material/datepicker';   
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ProfileComponent } from './components/profile/profile.component';
import { FleetListComponent } from './components/fleet-list/fleet-list.component';
import { FileEditPageComponent } from './pages/file-edit-page/file-edit-page.component';
import { DeployFileComponent } from './pages/deploy-file/deploy-file.component';
import { FleetPageComponent } from './pages/fleet-page/fleet-page.component';
import { LocalFileSelectorComponent } from './components/local-file-selector/local-file-selector.component';
import { CrmtDeviceSelectorComponent } from './components/crmt-device-selector/crmt-device-selector.component';
import { CrmtDateSelectorComponent } from './components/crmt-date-selector/crmt-date-selector.component';
import { CrmtJobReviewComponent } from './components/crmt-job-review/crmt-job-review.component';
import { RetrieveEditDeployComponent } from './pages/retrieve-edit-deploy/retrieve-edit-deploy.component';
import { PhonebookEditorComponent } from './components/phonebook-editor/phonebook-editor.component';
import { SmsDatabaseEditorComponent } from './components/sms-database-editor/sms-database-editor.component';
import { JobListComponent } from './pages/job-list/job-list.component';
import { CrmtJobListComponent } from './components/crmt-job-list/crmt-job-list.component';
import { CrmtJobStatusComponent } from './components/crmt-job-status/crmt-job-status.component';
import { SendCommandPageComponent } from './pages/send-command-page/send-command-page.component';
import { CrmtCommandSelectorComponent } from './components/crmt-command-selector/crmt-command-selector.component';
import { CrmtFileEditorComponent } from './components/crmt-file-editor/crmt-file-editor.component';
import { UusDatabaseEditorComponent } from './components/uus-database-editor/uus-database-editor.component';
import { CabRadioParametersEditorComponent } from './components/cab-radio-parameters-editor/cab-radio-parameters-editor.component';
import { AttachedSystemsParametersEditorComponent } from './components/attached-systems-parameters-editor/attached-systems-parameters-editor.component';
import { TrainCrewParametersEditorComponent } from './components/train-crew-parameters-editor/train-crew-parameters-editor.component';
import { PeriodTimerParametersEditorComponent } from './components/period-timer-parameters-editor/period-timer-parameters-editor.component';
import { ServiceNumberDatabaseEditorComponent } from './components/service-number-database-editor/service-number-database-editor.component';
import { ResponseActionDatabaseEditorComponent } from './components/response-action-database-editor/response-action-database-editor.component';
import { FaultLogViewerComponent } from './components/fault-log-viewer/fault-log-viewer.component';
import { FanUsageLogViewerComponent } from './components/fan-usage-log-viewer/fan-usage-log-viewer.component';
import { AuditLogComponent } from './pages/audit-log/audit-log.component';
import { CrmtAuditLogComponent } from './components/crmt-audit-log/crmt-audit-log.component';
import { CloudFileSelectorComponent } from './components/cloud-file-selector/cloud-file-selector.component';
import { UploadFileComponent } from './pages/upload-file/upload-file.component';
import { DeviceTypeSelectorComponent } from './components/device-type-selector/device-type-selector.component';
import { FileTypeSelectorComponent } from './components/file-type-selector/file-type-selector.component';
import { FleetTreeComponent } from './components/fleet-tree/fleet-tree.component';
import { CreateGroupComponent } from './components/create-group/create-group.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DeleteRowComponent } from './cell-renderers/delete-row/delete-row.component';
import { GroupListComponent } from './components/group-list/group-list.component';

import { CrmtNumericEditor } from './cell-editors/crmt-numeric-editor.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneNumberEditor } from './cell-editors/phone-number-editor.component';
import { StringEditor } from './cell-editors/string-editor.component';
import { DropdownRendererComponent } from './cell-editors/dropdown-editor.component';
import { RadioParameterEditor } from './cell-editors/radio-parameter-editor.component';
import { GenericFileEditorComponent } from './components/generic-file-editor/generic-file-editor.component';
import { GenericHeaderEditorComponent } from './components/generic-header-editor/generic-header-editor.component';
import { FileListComponent } from './components/file-list/file-list.component';
import { DeviceInfoComponent } from './pages/device-info/device-info.component';
import { StockHistoryComponent } from './components/stock-history/stock-history.component';
import { DetailsLinkComponent } from './cell-renderers/details-link/details-link.component';
import { DetailsLinkIIComponent } from './cell-renderers/details-link/details-link-ii.component';
import { InventoryComponent } from './components/inventory/inventory.component';
import { FleetListPageComponent } from './pages/fleet-list-page/fleet-list-page.component';
import { OrientationToolComponent } from './components/orientation-tool/orientation-tool.component';
import { DeviceRegistrationPageComponent } from './pages/device-registration-page/device-registration-page.component';
import { CertificateViewComponent } from './components/certificate-view/certificate-view.component';
import CrmtExitModalComponent  from './components/crmt-exit-modal/crmt-exit-modal.component';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { EditStateService } from './services/edit-state.service';
import { RadioButtonCellRendererComponent } from './cell-renderers/radio-button/radio-button-cell-renderer/radio-button-cell-renderer.component';
import { IconCellRendererComponent } from './cell-renderers/icon-cell-renderer/icon-cell-renderer.component';
import { FleetGroupSelectorModalComponent } from './components/fleet-group-selector-modal/fleet-group-selector-modal.component';
import { CallbackComponent } from './components/callback/callback.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { environment } from './../environments/environment';

import { Amplify } from 'aws-amplify';
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: environment.cognitoConfig.userPoolId,
      userPoolClientId: environment.cognitoConfig.userPoolClientId,
      loginWith: {
        oauth: {
          domain: environment.cognitoConfig.domain,  // Cognito hosted domain
          scopes: ['email', 'openid', 'phone', 'profile', 'aws.cognito.signin.user.admin'],  
          redirectSignIn: environment.cognitoConfig.redirectSignInURL,  
          redirectSignOut: environment.cognitoConfig.redirectSignOutURL, 
          responseType: 'code'
        }
      },
      userAttributes: {
        email: {
          required: true,
        },
      },
      passwordFormat: {
        minLength: 8,
        requireLowercase: true,
        requireUppercase: true,
        requireNumbers: true,
        requireSpecialCharacters: true,
      },
    },
  },
})
@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    FleetListComponent,
    FileEditPageComponent,
    DeployFileComponent,
    FleetPageComponent,
    LocalFileSelectorComponent,
    CrmtDeviceSelectorComponent,
    CrmtDateSelectorComponent,
    CrmtJobReviewComponent,
    RetrieveEditDeployComponent,
    PhonebookEditorComponent,
    SmsDatabaseEditorComponent,
    JobListComponent,
    CrmtJobListComponent,
    CrmtJobStatusComponent,
    SendCommandPageComponent,
    CrmtCommandSelectorComponent,
    CrmtFileEditorComponent,
    UusDatabaseEditorComponent,
    CabRadioParametersEditorComponent,
    AttachedSystemsParametersEditorComponent,
    TrainCrewParametersEditorComponent,
    PeriodTimerParametersEditorComponent,
    ServiceNumberDatabaseEditorComponent,
    ResponseActionDatabaseEditorComponent,
    FaultLogViewerComponent,
    FanUsageLogViewerComponent,
    AuditLogComponent,
    CrmtAuditLogComponent,
    CloudFileSelectorComponent,
    UploadFileComponent,
    DeviceTypeSelectorComponent,
    FileTypeSelectorComponent,
    FleetTreeComponent,
    CreateGroupComponent,
    DeleteRowComponent,
    DetailsLinkComponent,
    DetailsLinkIIComponent,
    GroupListComponent,
    CrmtNumericEditor,
    PhoneNumberEditor,
    RadioParameterEditor,
    StringEditor,
    DropdownRendererComponent,
    GenericFileEditorComponent,
    GenericHeaderEditorComponent,
    FileListComponent,
    DeviceInfoComponent,
    StockHistoryComponent,
    InventoryComponent,
    FleetListPageComponent,
    OrientationToolComponent,
    DeviceRegistrationPageComponent,
    CertificateViewComponent,
    CrmtExitModalComponent,
    RadioButtonCellRendererComponent,
    IconCellRendererComponent,
    FleetGroupSelectorModalComponent,
    CallbackComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    AgGridModule,
    IxModule.forRoot(),
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatFormFieldModule,
    MatNativeDateModule,
    NgxDaterangepickerMd.forRoot(),
    AmplifyAuthenticatorModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    CanDeactivateGuard,
    EditStateService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
