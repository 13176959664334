<div class="grid">
    <ix-layout-grid class="grid-layout">
        <ix-row>
            <ix-col class="header">
                <ix-content-header
                    variant="Secondary"
                    headerTitle="Edit Phonebook"
                    headerSubtitle="Modify the data as required">
                </ix-content-header>
            </ix-col>
            <ix-col class="tooltip-col">
                    <ix-icon name='about' my-custom-special-selector="any-value" aria-describedby="tooltip-2" size='24' class="info icon"></ix-icon>
                <ix-tooltip id="tooltip-2" for="[my-custom-special-selector='any-value']">
                    <ix-row>
                        <ix-col class="col-index">1.</ix-col>
                        <ix-col>Optional "+" international prefix may be used in Dialling Number.</ix-col>
                    </ix-row>
                    <ix-row>
                        <ix-col class="col-index">2.</ix-col>
                        <ix-col *ngIf="fileType == 'standard-phonebook'">Index numbers correspond to the storage location in the SIM EF<sub>ADN</sub> file and must be unique
                            but do not need to be consecutive or ordered.</ix-col>
                        <ix-col *ngIf="fileType == 'extended-phonebook'">Index numbers must be unique but do not need to be consecutive or ordered.</ix-col>
                    </ix-row>
                </ix-tooltip>
            </ix-col>
        </ix-row>
    </ix-layout-grid>
</div>

<app-generic-header-editor [hidden]=hideAppGenericHeaderEditor
    [header]="crmtFile?.header">
</app-generic-header-editor>

<ag-grid-angular
    class="ag-theme-alpine-dark ag-theme-ix"
    [columnDefs]="columnDefs"
    [rowData]="rowData"
    [rowSelection]="'single'"
    [animateRows]="true"
    [editType]="'fullRow'"
    [gridOptions]="gridOptions"
    (gridReady)="onGridReady($event)"
    (rowSelected)="onRowSelected($event)"
    [domLayout]="domLayout"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    (rowEditingStarted)="onRowEditingStarted($event)"
    (rowEditingStopped)="onRowEditingStopped($event)"
    (cellValueChanged)="onCellValueChanged($event)">
</ag-grid-angular>

<div class="wrapper">
    <ix-button 
        [disabled]="disableAddRow || disableEditing"
        class="m-1" 
        variant="primary" 
        (click)="addRow()">
        Add Row
    </ix-button>

    <ix-button 
        [disabled]="disableDeleteRow || disableEditing"
        class="m-1" 
        variant="primary" 
        (click)="deleteRow()">
        Delete Row
    </ix-button>

    <ix-button 
        [disabled]="disableDeleteRow || disableEditing"
        class="m-1" 
        variant="primary" 
        (click)="editRow()">
        Edit Row
    </ix-button>
</div>
<ng-template #customModalForErrorMessage>
    <ix-modal>
        <ix-modal-header> {{ errorMessage }} </ix-modal-header>
    </ix-modal>
</ng-template>