import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { GridApi, ICellRendererParams } from 'ag-grid-community';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { ApiService } from 'src/app/services/api.service';
import { UrlTree } from '@angular/router';
import { ModalService } from '@siemens/ix-angular';

@Component({
  selector: 'app-cell-renderer',
  templateUrl: './delete-row.component.html',
  styleUrls: [ './delete-row.component.css' ]
})
export class DeleteRowComponent implements OnInit, ICellRendererAngularComp {

  public icon = faTrashCan
  public value: any
  private api!: GridApi
  private node: any
  public showIcon: boolean = false
  private params!: ICellRendererParams
  private isRefreshed: boolean = false

  @ViewChild('customModal', { read: TemplateRef })
  customModalRef!: TemplateRef<any>;

  constructor(
    private _apiService: ApiService,private readonly modalService: ModalService,) {}

  agInit(params: ICellRendererParams): void {

    this.value = params.value 
    this.api = params.api
    this.node = params.node
    this.params = params

    const root = params.node.data.filePath[0]
    const type = params.node.data.type
    const empty = !params.node.allChildrenCount;

    this._apiService.getAccountInfo().subscribe({
      next: (data) => {
        this.showIcon = !this.isRefreshed && type == 'file' && root != data.organization ||
                        type == 'folder' && empty
      },
      error: (e) => {}
    })

  }
  
  refresh(params: ICellRendererParams): boolean {
    this.isRefreshed = true
    return false
  }

  ngOnInit(): void {}

  delete() {
    this.openModal();
  }

  private async openModal(): Promise<boolean | UrlTree> {
    const instance = await this.modalService.open({
      size: '360',
      content: this.customModalRef,
      data: ''
    });

    return new Promise<boolean | UrlTree>((resolve) => {
      instance.onClose.on(() => {
        const isFolder = this.node.data.type == 'folder'

        const event = {
          type: 'deleteRowEvent', // Custom event type
          params: this.params
        };
    
        if(isFolder) {
          const filePath = this.node.data.filePath
          const group = filePath[filePath.length - 1]
            this._apiService.fleetRemoveGroup(group).subscribe({
            next: (data) => {
              this.api.applyTransaction({ remove: [this.node]})
              // Dispatch the custom event for grid API
              this.params.api.dispatchEvent(event);
            },
            error: (e) => {
              console.log(e.message)
            }
          })
        }
        else {
          const filePath = this.node.data.filePath
          const thingName = this.node.data.deviceName
          const group = filePath[filePath.length - 2]
          this._apiService.fleetRemoveThingFromGroup(group, thingName).subscribe({
            next: (data) => {
              this.api.applyTransaction({ remove: [this.node]})
              // For tree data the apply transaction function does not work property
              // so as fall back remove deleted node from gid manually and update tow data
              // Dispatch the custom event for grid API
              this.params.api.dispatchEvent(event);
            },
            error: (e) => {
              console.log(e.message)
            }
          })
        } 
          resolve(true);
      });

      instance.onDismiss.on(() => {
        resolve(true);
      });

      instance.htmlElement.addEventListener('keydown',(keyboardEvent: KeyboardEvent) => {});
    });
  }
}
