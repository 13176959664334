import { Injectable } from '@angular/core';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { ApiService } from './api.service';
import { Observable, of, switchMap, from, mergeMap } from 'rxjs';

export enum UserLevel {
  SUPER = 4,      // Intended for Siemens admin only
  PRIVILEGED = 3, // Top level customer access (can add organizations and subgroups)
  STANDARD = 2,   // Restricted customer access (most operations for maintenance and update)
  READ_ONLY = 1,  // No ability to modify any aspect of the system. View / download data only
  NONE = 0        // Not really used. The backend requires that users have permissions.
}

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  constructor(private apiService: ApiService) { }

  public hasPermission(required: number): Observable<boolean> {
    return this.getUserLevel().pipe(
      switchMap(userLevel => of(userLevel >= required))
    )
  }

  public getUserLevel(): Observable<UserLevel> {

    return from(getCurrentUser()).pipe(
      switchMap(async user => {
        if (!user) {
          return of(UserLevel.NONE);
        }

        // Get user session and extract groups
        const { tokens } = await fetchAuthSession();
        // TODO switch this function to be async to allow this await

        return this.apiService.getAccountInfo().pipe(
          switchMap(accountInfo => {
            const groups = tokens?.idToken?.payload['cognito:groups'] as string[];
            let userLevel: UserLevel = UserLevel.NONE;

            if (groups.includes(`${accountInfo.appPrefix}-super`)) {
              userLevel = UserLevel.SUPER;
            } else if (groups.includes(`${accountInfo.appPrefix}-privileged`)) {
              userLevel = UserLevel.PRIVILEGED;
            } else if (groups.includes(`${accountInfo.appPrefix}-standard`)) {
              userLevel = UserLevel.STANDARD;
            } else if (groups.includes(`${accountInfo.appPrefix}-read`)) {
              userLevel = UserLevel.READ_ONLY;
            }

            return of(userLevel);
          })
        );
      })
    ).pipe(
      switchMap(innerObservable => innerObservable)
    );
    
  }

}

